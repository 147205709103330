// FIXME 要確認

import dateFormat, { DateFormatI18n } from 'dateformat';

export const languageCodes = Object.freeze({
  "ja": "ja", 
  "en": "en",
});

const defaultLanguageCode = "ja";

export type languageCodesEnum = keyof typeof languageCodes;

export const languageCodesLowerCase = Object.keys(
  languageCodes
).map((code) => code.toLowerCase());

export const dateFormats: Record<string, DateFormatI18n> = {
  ja: {
    dayNames: [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土",
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日",
    ],
    monthNames: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "１月",
      "２月",
      "３月",
      "４月",
      "５月",
      "６月",
      "７月",
      "８月",
      "９月",
      "１０月",
      "１１月",
      "１２月",
    ],
    timeNames: ["a", "p", "午前", "午後", "A", "P", "AM", "PM"],
  },
  en: {
    dayNames: [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    monthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
  },
};


export const defaultLanguage = languageCodes[defaultLanguageCode];

export const initLanguageCodeObjects = <T>(arg: T) => {

  const object:Record<string, T[]> = {};
  Object.keys(languageCodes).forEach((language) => {
    object[language] = [arg];
  });

  return object;
};

export const initLanguageCodeObject = <T>(arg: T) => {

  const object:Record<string, T> = {};
  Object.keys(languageCodes).forEach((language) => {
    object[language] = arg;
  });

  return object;
};

export const getLanguageCode = (route: string) => {
  const defaultLanguage = languageCodesLowerCase[0];
  const languageParameter = route.split("/")[1];
  
  if (Object.values(languageCodes).includes(languageParameter)){
    return languageParameter
  }
  return defaultLanguage;
};

export const formatDate = (value:Date|null, language: string, formal?:boolean) => {
  dateFormat.i18n = dateFormats[language] || dateFormats['ja'];
  if (dateFormat.i18n == dateFormats['ja']){
    if (formal){
      return value ? dateFormat(value, 'yyyy年m月d日'):""
    }else{
      return value ? dateFormat(value, 'yyyy.mm.dd'):""
    }
  }else{
    return value ? dateFormat(value, 'mm dd, yyyy'):""
  }
}

export default languageCodes;
