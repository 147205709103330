import { render, staticRenderFns } from "./NewsList.vue?vue&type=template&id=53ff69b1&"
import script from "./NewsList.vue?vue&type=script&lang=ts&"
export * from "./NewsList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/localization/toppage.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Csrc%5Cviews%5Chome%5Ccomponents%5CNewsList.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports