


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SortOrder } from '@kentico/kontent-delivery';
import NewsItem from './NewsItem.vue';
import { Client } from '@/repository/kontent/stores/base/client';
import { Newsitem } from '@/repository/kontent/models/newsitem';

@Component({
  components: {
    NewsItem,
  },
})
export default class NewsList extends Vue {
  private newsList: Array<Newsitem> = [];

  @Watch('$route')
  async onRouteChanged(): Promise<void> {
    this.newsList = (
      await Client.items<Newsitem>()
        .type('newsitem')
        .languageParameter(this.$i18n.locale)
        .equalsFilter('system.language', this.$i18n.locale)
        .orderParameter('elements.date', SortOrder.desc)
        .limitParameter(4)
        .toPromise()
    ).items;
  }

  async mounted() {
    this.newsList = (
      await Client.items<Newsitem>()
        .type('newsitem')
        .languageParameter(this.$i18n.locale)
        .equalsFilter('system.language', this.$i18n.locale)
        .orderParameter('elements.date', SortOrder.desc)
        .limitParameter(4)
        .toPromise()
    ).items;
  }
}
