import Vue from 'vue';
import { reactive } from '@vue/composition-api';
import Store from './base/store';
import { Home } from "../models/home";
import { Newsitem } from '../models/newsitem';
import { Notice } from '../models/_notice';
import { Linkset } from '../models/_linkset';

class Ext extends Store<Home>{
  public get notices(){
    return this._i18nedList[0]?.noticeList?.value.map((x:any) => x as Notice) ?? [];
  }
  public get services() {
    return this._i18nedList[0]?.serviceList?.value.map((x:any) => x as Linkset) ?? [];
  }
  public get news() {
    return this._i18nedList[0]?.newsList?.value.map((x:any) => x as Newsitem) ?? [];
  }
  public get blogPosts() {
    return this._i18nedList[0]?.blogList?.value.map((x:any) => x as Newsitem) ?? [];
  }
}
const x = new Ext('home');
x.restore(); // 任意

export { Home };
export default reactive(x);
