















import { Component, Vue, Prop } from 'vue-property-decorator';

export type CaseStudy = {
  route: string;
  img: string;
  title: string;
  description: string;
};

@Component
export default class CaseStudyItem extends Vue {
  @Prop(Array) public caseStudy!: Array<CaseStudy>;
}
