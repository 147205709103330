
















import { Component, Vue, Prop } from 'vue-property-decorator';
import BlogPostItem from './BlogPostItem.vue';
import { Newsitem } from '@/repository/kontent/stores/newsStore';

@Component({
  components: {
    BlogPostItem,
  },
})
export default class BlogPostList extends Vue {
  @Prop({ default: [] }) blogPostList!: Array<Newsitem>;
}
