





































import { Component, Vue } from 'vue-property-decorator';
import CaseStudyItem, { CaseStudy } from './CaseStudyItem.vue';

@Component({
  components: {
    CaseStudyItem,
  },
})
export default class CaseStudyList extends Vue {
  // TODO caseStudyListsをKontentから取得
  private caseStudyLists: Array<CaseStudy> = [
    {
      route: 'dummy',
      img: '@/_mitsue-modules/src/img/top/top_casestudy_01.jpg',
      title: '株式会社KADOKAWA様',
      description:
        'Microsoft Azureを使った改修を行いました。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。',
    },
    {
      route: 'dummy',
      img: '@/_mitsue-modules/src/img/top/top_casestudy_02.jpg',
      title: '美津濃株式会社様',
      description: 'Microsoft Azureを使った改修を行いました。ダミーテキストです。',
    },
    {
      route: 'dummy',
      img: '@/_mitsue-modules/src/img/top/top_casestudy_03.jpg',
      title: 'タワーレコード株式会社様',
      description:
        'Microsoft Azureを使った改修を行いました。ダミーテキストです。ダミーテキストです。ダミーテキストです。',
    },
    {
      route: 'dummy',
      img: '@/_mitsue-modules/src/img/top/top_casestudy_01.jpg',
      title: 'dummydummy4',
      description:
        'ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。',
    },
    {
      route: 'dummy',
      img: '@/_mitsue-modules/src/img/top/top_casestudy_02.jpg',
      title: 'dummydummy5',
      description:
        'ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。ダミーテキストです。',
    },
  ];
}
