












import { Component, Vue, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { Servicecategory } from '@/repository/kontent/models/servicecategory';

@Component
export default class ServiceListItem extends Vue {
  @Prop(Object) service!: Servicecategory;
  private get location() {
    return {
      name: 'ServiceList',
      hash: `#${this.service.slug.value}`,
    } as Location;
  }
}
