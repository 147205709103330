












































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import I18n, { Languages } from '@/i18n/index';
import HomeStore from '@/repository/kontent/stores/homeStore';
import BlogPostList from './components/BlogPostList.vue';
import CaseStudyList from './components/CaseStudyList.vue';
import NewsList from './components/NewsList.vue';
import ServiceList from './components/ServiceList.vue';
import NoticeList from './components/NoticeList.vue';
import { Client } from '@/repository/kontent/stores/base/client';
import { Home } from '@/repository/kontent/models/home';

@Component({
  components: { BlogPostList, ServiceList, CaseStudyList, NewsList, NoticeList },
})
export default class Index extends Vue {
  private i18n = I18n;
  private home: Array<Home> = [];

  @Watch('$route')
  async onRouteChanged(): Promise<void> {
    this.home = (
      await Client.items<Home>().type('home').languageParameter(this.$i18n.locale).toPromise()
    ).items;
  }

  private get blogPosts() {
    return HomeStore?.blogPosts;
  }

  async mounted() {
    this.home = (
      await Client.items<Home>().type('home').languageParameter(this.$i18n.locale).toPromise()
    ).items;
  }
}
