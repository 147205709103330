



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ServiceItem from './ServiceItem.vue';
import I18n from '@/i18n/index';
import { Client } from '@/repository/kontent/stores/base/client';
import { Servicecategory } from '@/repository/kontent/models/servicecategory';
import { Home } from '@/repository/kontent/models/home';

@Component({
  components: {
    ServiceItem,
  },
})
export default class ServiceList extends Vue {
  private services: Array<Servicecategory> = [];
  private home: Array<Home> = [];

  @Watch('$route')
  async onRouteChanged(): Promise<void> {
    this.services = (
      await Client.items<Servicecategory>()
        .type('servicecategory')
        .languageParameter(this.$i18n.locale)
        .equalsFilter('system.language', this.$i18n.locale)
        .toPromise()
    ).items;
    this.home = (
      await Client.items<Home>().type('home').languageParameter(this.$i18n.locale).toPromise()
    ).items;
  }

  async mounted() {
    this.services = (
      await Client.items<Servicecategory>()
        .type('servicecategory')
        .languageParameter(this.$i18n.locale)
        .equalsFilter('system.language', this.$i18n.locale)
        .toPromise()
    ).items;
    this.home = (
      await Client.items<Home>().type('home').languageParameter(this.$i18n.locale).toPromise()
    ).items;
  }
}
