















import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { Location } from 'vue-router';
import { Newsitem } from '@/repository/kontent/stores/newsStore';

@Component
export default class BlogPostItem extends Vue {
  @Prop(Object) blogPost!: Newsitem;

  private format = (s: string) => (dayjs(s).isValid() ? dayjs(s).format('YYYY.MM.DD') : '-');

  // FIXME ブログへのリンク
  private get location() {
    return {
      name: 'News',
      params: { name: this.blogPost.system.name },
    } as Location;
  }
}
