










import { Component, Vue, Watch } from 'vue-property-decorator';
import { Notice } from '@/repository/kontent/models/_notice';
import languageCodes, { formatDate } from '@/repository/kontent/utilities/languageCodes';
import I18n from '@/i18n/index';
import { Client } from '@/repository/kontent/stores/base/client';
import NoticeItem from './NoticeItem.vue';

@Component({
  components: {
    NoticeItem,
  },
})
export default class NoticeList extends Vue {
  private notices: Array<Notice> = [];

  @Watch('$route')
  async onRouteChanged(): Promise<void> {
    this.notices = (
      await Client.items<Notice>()
        .type('_notice')
        .languageParameter(this.$i18n.locale)
        .equalsFilter('system.language', this.$i18n.locale)
        .orderByDescending('elements.notice_date')
        .toPromise()
    ).items;
  }

  async mounted() {
    this.notices = (
      await Client.items<Notice>()
        .type('_notice')
        .languageParameter(this.$i18n.locale)
        .equalsFilter('system.language', this.$i18n.locale)
        .orderByDescending('elements.notice_date')
        .toPromise()
    ).items;
  }
}
