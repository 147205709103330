

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Notice } from '@/repository/kontent/models/_notice.ts';
import languageCodes, { formatDate } from '@/repository/kontent/utilities/languageCodes';
import I18n from '@/i18n/index';
import { Client } from '@/repository/kontent/stores/base/client';

@Component
export default class NoticeItem extends Vue {
  @Prop(Object) notice!: Notice;

  private i18n = I18n;
  private get date() {
    return formatDate(this.notice.notice_date?.value, I18n.language);
  }
  private get routerLink() {
    return this.notice?.routerlink.value;
  }
  private get routerParam() {
    return this.routerLink?.split('/');
  }
  private get routerType() {
    return this.routerParam[0];
  }
  private get externalLink() {
    return this.notice?.externallink.value;
  }
  private get groupVal() {
    return this.notice.notice_group.value[0];
  }
  private get groupName() {
    return this.groupVal.name;
  }
  private get groupClass() {
    return `is-${this.groupVal.codename}`;
  }
  private get body() {
    return this.notice.notice_body.value;
  }
}
