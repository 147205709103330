



















import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { Location } from 'vue-router';
import { Newsitem } from '@/repository/kontent/stores/newsStore';
import { LabelCssVal } from '@/_ns_modules/src/ts/labelCssUtil';
import languageCodes, { formatDate } from '@/repository/kontent/utilities/languageCodes';
import I18n from '@/i18n/index';

@Component
export default class NewsItem extends Vue {
  @Prop(Object) news!: Newsitem;

  private format = (s: string) => (dayjs(s).isValid() ? dayjs(s).format('YYYY.MM.DD') : '-');

  private get location() {
    if (!this.news.date.value) return { name: 'NewsList' };

    const year = dayjs(this.news.date.value).format('YYYY');
    return {
      name: 'News',
      params: { year, article: this.news.url.value },
    } as Location;
  }

  private get newsGroupVal() {
    const newsGroupArr = this.news.newsGroup.value;
    return newsGroupArr.length > 0 ? newsGroupArr[0] : null;
  }

  private get groupVal() {
    return this.newsGroupVal?.codename ?? 'other';
  }
  private get groupClass() {
    const labelVal = this.newsGroupVal?.codename ?? 'other';
    return LabelCssVal[labelVal];
  }
  private get thumbnail() {
    return this.news.thumbnail.value[0]?.contract.url;
  }
}
